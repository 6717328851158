var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stock-venta mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Stock de ventas ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha inicial","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha final","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":{
                  itemsPerPageText: 'Productos',
                  itemsPerPageOptions: [10, 25, 50, -1],
                },"options":{
                  itemsPerPage: -1,
                },"loading":_vm.loading_t,"search":_vm.search,"items":_vm.lista},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pl-0",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.download()}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.costo_p",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.costo_p))+" ")]}},{key:"item.costo_proyectado",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.costo_proyectado))+" ")]}},{key:"item.venta_s",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.venta_s))+" ")]}},{key:"item.venta_proyectada",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.venta_proyectada))+" ")]}},{key:"item.utilidad",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("utility")(item.utilidad))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("Totales")]),_c('th',[_vm._v(_vm._s(_vm.totalizacion.inicial))]),_c('th',[_vm._v(_vm._s(_vm.totalizacion.entradas))]),_c('th',[_vm._v(_vm._s(_vm.totalizacion.salidas))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.totalizacion.final))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalizacion.costo)))]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalizacion.venta)))])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }